import React from "react";
import { graphql } from "gatsby";
import { SEO } from "@kickstartds/gatsby-theme-kickstartds/src/components/Seo";

import { cleanObjectKeys } from "@kickstartds/jsonschema2graphql/build/dehashing";

import { Layout } from "../@kickstartds/gatsby-theme-kickstartds/components/Layout";
import Concierge from "../components/Concierge";

function ConciergeTemplate({ data }) {
  const headerEn = data.allKickstartDsHeader.edges.find(
    (header) => !header.node.component.activeEntry__254f.includes("de")
  );
  const header = cleanObjectKeys(headerEn.node.component);

  const footerEn = data.allKickstartDsFooter.edges.find(
    (footer) =>
      !footer.node.component.sections__17ac[1].headline__b113.includes(
        "Kontakt"
      )
  );
  const footer = cleanObjectKeys(footerEn.node.component);

  const imageUrl = data.file.childImageSharp.fixed.src;

  header["announcementBar"] = { content: "" };
  header["dark"] = true;
  footer["dark"] = true;

  return (
    <div ks-theme="dark" ks-inverted="true">
      <Layout header={header} footer={footer}>
        <SEO
          title="Design System Concierge - Your AI-powered Design System assistant"
          description="Get Design System answers, fast! Our AI-powered Concierge offers insights from our vast Design System database sourcing expert content only. Ask any question, get pointed to the right source. Your ultimate Design System assistant."
          keywords="Design System, Concierge, AI, Assistant"
          image={imageUrl}
          cardImage={imageUrl}
          twitterCreator="@kickstartDS"
        />
        <Concierge />
      </Layout>
    </div>
  );
}

export default ConciergeTemplate;

export const query = graphql`
  query CONCIERGE {
    allKickstartDsHeader {
      edges {
        node {
          component {
            ...HeaderComponentDeepNesting
          }
        }
      }
    }
    allKickstartDsFooter {
      edges {
        node {
          component {
            ...FooterComponentDeepNesting
          }
        }
      }
    }
    file(relativePath: { eq: "images/concierge-socialpreview.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 880, height: 464) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
