import React, { FC, useCallback, useRef, useState } from "react";
import { Concierge as ConciergeComponent } from "@kickstartds/design-system/dist/components/concierge/ConciergeComponent";
import { CustomEventDataType, SSE, SSEOptionsMethod } from "sse-ts";
import { Source } from "@kickstartds/design-system/dist/components/concierge/ConciergeProps";
import { Database } from "./dbTypes";
import type { CreateCompletionResponse } from "openai";

const placeholder = `Why should I care about Design Systems, and what are the main reasons to start one?

... or maybe you'd like to know something about kickstartDS... I know about that as well, so fire away!
`;

const questions = [
  "What is the definition of a Design System?",
  "What are the 5 main benefits of using Design Token in your Design System?",
  "What are the main reasons to prefer React as your frontend framework, especially when building a Design System?",
  "When do you need a Design System?",
  "How can I convince others of the value of having a Design System?",
  "How would I go about measuring the adoption of my Design System, to calculate a ROI?",
];

const index = [
  { title: "https://acorn.firefox.com/", url: "https://acorn.firefox.com/" },
  { title: "https://atlassian.design/", url: "https://atlassian.design/" },
  {
    title: "https://atomicdesign.bradfrost.com/",
    url: "https://atomicdesign.bradfrost.com/",
  },
  { title: "https://backlight.dev/", url: "https://backlight.dev/" },
  { title: "https://blog.damato.design/", url: "https://blog.damato.design/" },
  { title: "https://bradfrost.com/", url: "https://bradfrost.com/" },
  {
    title: "https://build.washingtonpost.com/",
    url: "https://build.washingtonpost.com/",
  },
  { title: "https://chakra-ui.com/", url: "https://chakra-ui.com/" },
  { title: "https://clarity.design/", url: "https://clarity.design/" },
  { title: "https://cloudscape.design/", url: "https://cloudscape.design/" },
  {
    title: "https://comet.discoveryeducation.com",
    url: "https://comet.discoveryeducation.com",
  },
  { title: "https://cultureamp.design/", url: "https://cultureamp.design/" },
  { title: "https://danmall.com/", url: "https://danmall.com/" },
  {
    title: "https://design-system.service.gov.uk",
    url: "https://design-system.service.gov.uk",
  },
  {
    title: "https://design-tokens.github.io/",
    url: "https://design-tokens.github.io/",
  },
  { title: "https://design.gitlab.com/", url: "https://design.gitlab.com/" },
  {
    title: "https://designstrategy.guide/",
    url: "https://designstrategy.guide/",
  },
  {
    title: "https://designsystem.digital.gov/",
    url: "https://designsystem.digital.gov/",
  },
  {
    title: "https://designsystem.webstandards.ca.gov",
    url: "https://designsystem.webstandards.ca.gov",
  },
  {
    title: "https://digitaldesign.vattenfall.com",
    url: "https://digitaldesign.vattenfall.com",
  },
  { title: "https://docs.astro.build/", url: "https://docs.astro.build/" },
  { title: "https://docs.tokens.studio/", url: "https://docs.tokens.studio/" },
  {
    title: "https://druids.datadoghq.com/",
    url: "https://druids.datadoghq.com/",
  },
  { title: "https://eightshapes.com/", url: "https://eightshapes.com/" },
  {
    title: "https://help.knapsack.cloud/",
    url: "https://help.knapsack.cloud/",
  },
  { title: "https://mui.com/", url: "https://mui.com/" },
  { title: "https://nextjs.org/", url: "https://nextjs.org/" },
  { title: "https://nuxtjs.org/", url: "https://nuxtjs.org/" },
  { title: "https://orbit.kiwi/", url: "https://orbit.kiwi/" },
  {
    title: "https://paste.twilio.design/",
    url: "https://paste.twilio.design/",
  },
  {
    title: "https://polaris.shopify.com/",
    url: "https://polaris.shopify.com/",
  },
  { title: "https://primer.style/", url: "https://primer.style/" },
  {
    title: "https://react-spectrum.adobe.com/",
    url: "https://react-spectrum.adobe.com/",
  },
  { title: "https://react.dev/", url: "https://react.dev/" },
  { title: "https://remix.run/", url: "https://remix.run/" },
  { title: "https://rivet.iu.edu/", url: "https://rivet.iu.edu/" },
  { title: "https://spectrum.adobe.com/", url: "https://spectrum.adobe.com/" },
  { title: "https://svelte.dev/", url: "https://svelte.dev/" },
  { title: "https://ux.mailchimp.com/", url: "https://ux.mailchimp.com/" },
  { title: "https://vuejs.org/", url: "https://vuejs.org/" },
  {
    title: "https://www.a11yproject.com/",
    url: "https://www.a11yproject.com/",
  },
  {
    title: "https://www.delldesignsystem.com/",
    url: "https://www.delldesignsystem.com/",
  },
  {
    title: "https://www.designbetter.co/",
    url: "https://www.designbetter.co/",
  },
  {
    title: "https://www.designsystemchecklist.com",
    url: "https://www.designsystemchecklist.com",
  },
  {
    title: "https://www.designsystems.com/",
    url: "https://www.designsystems.com/",
  },
  { title: "https://www.duetds.com/", url: "https://www.duetds.com/" },
  {
    title: "https://www.kickstartDS.com/",
    url: "https://www.kickstartDS.com/",
  },
  { title: "https://www.knapsack.cloud/", url: "https://www.knapsack.cloud/" },
  {
    title: "https://www.lightningdesignsystem.com",
    url: "https://www.lightningdesignsystem.com",
  },
  {
    title: "https://www.michaelmang.dev/",
    url: "https://www.michaelmang.dev/",
  },
  { title: "https://www.radix-ui.com/", url: "https://www.radix-ui.com/" },
  { title: "https://www.supernova.io/", url: "https://www.supernova.io/" },
];

const statusMessages = {
  idle: "Concierge is awaiting your request!",
  loading: "Working: Concierge is researching...",
  responding: "Working: Concierge is responding...",
  error: "Concierge is not available",
  canthelp: "Sorry, I couldn't find anything relevant to your question ☹️",
};

const edgeFunctionUrl = process.env.GATSBY_SUPABASE_ANSWER_URL || "";

interface PageSectionResponse {
  pageSections: Database["public"]["Functions"]["match_sections"]["Returns"];
}

type ConciergeResponse =
  | (CreateCompletionResponse & { pageSections: never })
  | (PageSectionResponse & { choices: never });

const Concierge: FC = () => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [answer, setAnswer] = useState("");
  const [sources, setSources] = useState<Source[]>([]);
  const [statusCode, setStatusCode] = useState<string>("idle");
  const eventSourceRef = useRef<SSE>();

  const status = {
    code: statusCode,
    ...statusMessages,
  };

  function getSourceThumbnail(sourceUrl: string) {
    const url = new URL(sourceUrl);
    const domain = url.hostname
      .replace("rivet.iu", "rivet.ui")
      .replace(/\./g, "_");
    const path = url.pathname.endsWith("/")
      ? url.pathname.substring(1, url.pathname.length - 1).replace(/\//g, "-")
      : url.pathname.substring(1, url.pathname.length).replace(/\//g, "-");
    return `https://pzdzoelitkqizxopmwfg.supabase.co/storage/v1/object/public/screenshots/${domain}/${path}-chromium.png`;
  }

  const handleConciergeConfirm = useCallback(async () => {
    document
      .querySelector(".template--concierge")
      ?.scrollIntoView({ block: "center" });

    setAnswer("");
    setStatusCode("loading");

    if (textAreaRef && textAreaRef.current) textAreaRef.current.disabled = true;

    const eventSource = new SSE(edgeFunctionUrl, {
      method: SSEOptionsMethod.POST,
      headers: {
        apikey: process.env.GATSBY_SUPABASE_ANON_KEY || "",
        Authorization: `Bearer ${process.env.GATSBY_SUPABASE_ANON_KEY || ""}`,
        "Content-Type": "application/json",
      },
      payload: JSON.stringify({ question: textAreaRef.current?.value }),
    });

    function handleError<T>(err: T) {
      setStatusCode("error");
      console.error(err);
    }

    eventSource.addEventListener("error", handleError);
    eventSource.addEventListener("message", (e) => {
      const dataEvent = e as CustomEventDataType;
      try {
        if (dataEvent.data === "[DONE]") {
          setStatusCode("idle");
          return;
        }

        setStatusCode("responding");

        const completionResponse: ConciergeResponse = JSON.parse(
          dataEvent.data
        );

        if (completionResponse.pageSections) {
          setSources(
            completionResponse.pageSections.reduce<Source[]>(
              (grouped, pageSection, index) => {
                const source = grouped.find(
                  (source) => source.url === pageSection.page_url
                );

                if (!source) {
                  grouped.push({
                    id: `source-${index}`,
                    thumbnail: getSourceThumbnail(pageSection.page_url),
                    url: pageSection.page_url,
                    title: pageSection.page_title,
                    summary: pageSection.page_summary,
                    sections: [
                      {
                        excerpt: pageSection.content,
                        relevance: (
                          (pageSection.similarity as number) * 100
                        ).toFixed(2),
                      },
                    ],
                  });
                } else {
                  source.sections.push({
                    excerpt: pageSection.content,
                    relevance: (
                      (pageSection.similarity as number) * 100
                    ).toFixed(2),
                  });
                }

                return grouped;
              },
              []
            )
          );
        } else {
          const [{ text }] = completionResponse.choices;

          setAnswer((answer) => {
            return (answer ?? "") + text;
          });
        }
      } catch (err) {
        handleError(err);
      }
    });

    eventSource.stream();
    eventSourceRef.current = eventSource;

    setStatusCode("loading");
  }, []);

  function handleResetPrompt() {
    document.querySelector("#msg")?.scrollIntoView({ block: "center" });
    eventSourceRef.current?.close();
    eventSourceRef.current = undefined;
    if (textAreaRef.current) {
      textAreaRef.current.value = "";
      textAreaRef.current.focus();
      textAreaRef.current.disabled = false;
    }
    setAnswer("");
    setSources([]);
    setStatusCode("idle");
  }
  return (
    <ConciergeComponent
      placeholder={placeholder}
      answer={answer}
      sources={sources}
      index={index}
      status={status}
      questions={questions}
      ref={textAreaRef}
      handleResetPrompt={handleResetPrompt}
      handleConciergeConfirm={handleConciergeConfirm}
    />
  );
};

export default Concierge;
